<script setup>
import { ref, onMounted } from 'vue';
import NavBar from '@/Components/Layout/Navbar.vue'
import appApi from "@/services/api/global/app"
import employeeApi from "@/services/api/settings/employees/employees"
import centersApi from "@/services/api/settings/centers/centers"
import { useUserStore } from '@/stores/user'
import { useLayoutStore } from '@/stores/layout'
import { useSchedulerStore } from '@/stores/scheduler.js'
import { getLocation } from '@/utils/geoLocation';
import { showNotification } from "@/utils/notifications"

import { useI18n } from "vue-i18n"
const { t } = useI18n();

const userStore = useUserStore()
const layoutStore = useLayoutStore()
const schedulerStore = useSchedulerStore()

const menuLoaded = ref(false);

onMounted(async () => {
    // Verifica si el menú ya está cargado en el store
    if (!layoutStore.mainMenu) {
        
        layoutStore.isLoading = true

        try {
            // Lanzamos ambas peticiones paralelamente
            const [responseLayout, responseCountries, responseBusinessTypes, responseTimeZones, responseAttendanceStatus, wsResponseInstances ] = await Promise.all([
                appApi.getLayout(),
                appApi.getCountries(),
                appApi.getBusinessTypes(),
                appApi.getTimeZones(),
                employeeApi.attendanceStatus(userStore.profile.id),
                centersApi.getAllWhatsAppInstances()
            ]);

            // Almacenamos info global en el store
            layoutStore.setMainMenu(responseLayout)
            layoutStore.setCoutries(responseCountries.data)
            layoutStore.setBusinessTypes(responseBusinessTypes)
            layoutStore.setTimeZones(responseTimeZones.data)
            userStore.setAttendanceStatus(responseAttendanceStatus.data)
            schedulerStore.setWhatsAppInstances(wsResponseInstances.data)

            menuLoaded.value = true; // Indica que el menú ya está cargado

        } catch (error) {
            console.error("Error al cargar las opciones del menú:", error);
        } finally {
            layoutStore.isLoading = false;
        }
    } else {
        // Si el menú ya está en el store, simplemente marca como cargado
        menuLoaded.value = true;
    }


});

const handleAttendanceChange = async () => {

    layoutStore.isLoading = true


    getLocation().then(async result => {
        if (typeof result === 'number') {
            if (result === 0) {
                showNotification({ type: 'error', message: t('profile.clockIn.locationNotAllowed.info') });
            } else if (result === 1) {
                showNotification({ type: 'error', message: t('profile.clockIn.browserLocationNotAllowed.info') });
            }
        } else {
            const location = `${result.latitude},${result.longitude}`; // Crea un string de ubicación en formato latitud,longitud
            const payload = { location: location }; // Asegúrate de que el payload está correctamente formado

            try {
                let response;
                if (!userStore.attendanceStatus) { // 0 -> No ha fichado
                    response = await employeeApi.clockIn(userStore.profile.id, payload);
                    userStore.setAttendanceStatus(1); // Ajusta el estado de fichaje en el store
                } else {
                    response = await employeeApi.clockOut(userStore.profile.id, payload);
                    userStore.setAttendanceStatus(0); // Ajusta el estado de fichaje en el store
                }
                showNotification({ type: 'success', message: t('profile.attendanceStatus.ok') }); // Muestra un mensaje de éxito
            } catch (error) {
                const errorMsg = error.response?.data?.errors ?? error.response?.data?.message ?? t('profile.attendanceStatus.error');
                showNotification({ type: 'error', message: errorMsg });
            } finally {
                layoutStore.isLoading = false
            }
        }
    }).catch(error => {
        layoutStore.isLoading = false
        console.error('Error en la geolocalización:', error);
        showNotification({ type: 'error', message: t('profile.clockIn.locationNotAllowed.info') });
    });
};

</script>
<template>
    <div class="h-full overflow-hidden bg-blue-50" v-if="menuLoaded">
        <NavBar :menuOptions="layoutStore.mainMenu" @attendanceChanged="handleAttendanceChange" />
        <main class="h-full">
            <div class="w-full h-full">
                <router-view />
            </div>
        </main>
    </div>
</template>